.cPost{
}

.cPost h2{
    text-align: center;
}



.cPost .innerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;

}

.cPost .image{
    flex: 3;
    display: flex;
    /* justify-content: center; */
}

.cPost .image a{
    display: block;
    width: 100%;
}

.cPost .image img{
    max-width: 100%;
    height: auto;
    width: 100%;
    min-height: 272px;
    height: 520px;
    object-fit: cover;
}

.cPost .caption{
    flex: 1 1;
    text-align: center;
}

.cPost .caption{
    text-decoration: none;
    padding: 0 25px;
}

.cPost .caption a{
    text-decoration: none;
}

.cPost .caption a:hover h3{
    color: #edae77;
}

.cPost .caption h3{
    color: #000000;
    font-size: 23px;
    font-family: "Crimson Text", serif;
    font-weight: 400;
}

.cPost .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    color: #898989;
}



