.tPosts{

}

.tPosts h2{
    text-align: center;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 50px;
}

.tPosts h2::after{
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: #edae77;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.tPosts ul{
    list-style: none;
    padding: 0;
}

.tPosts ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.tPosts ul li:last-child{
    margin-bottom: 0;
}

.tPosts ul li .image{
    width: 170px;
    height: 120px;
    position: relative;
}

.tPosts ul li .image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.tPosts ul li .caption{
    padding: 10px;
    flex: 2;
}

.tPosts ul li .caption a{
    text-decoration: none;
}

.tPosts ul li .caption a:hover h3{
    color: #edae77;
}

.tPosts ul li .caption a h3{
    font-family: 'Roboto Condensed', sans-serif;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.tPosts ul li .caption .postDetails .date{
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #898989;
}




@media only screen and (max-width: 1024px) {
    .tPosts h2{
        margin-bottom: 25px;
        margin-top: 0;
    }

    .tPosts ul li .caption a h3{
        font-size: 16px;
    }

}