.featuredPost{

}

.featuredPost .innerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.featuredPost .innerWrapper .image{
    flex: 1.3;
  display: flex;
  justify-content: center;
}

.featuredPost .innerWrapper .image img {
    max-width: 100%;
    height: auto;
  }

.featuredPost .innerWrapper .caption{
    flex: 1;
  text-align: center;
}

.featuredPost .innerWrapper .caption a{
    text-decoration: none;
}

.featuredPost .innerWrapper .caption a:hover h3{
  color: #edae77;
}

.featuredPost .innerWrapper .caption a h3{
    color: #000000;
    font-size: 23px;
    font-family: "Crimson Text", serif;
    font-weight: 400;
}

.featuredPost .innerWrapper .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #898989;
}

@media only screen and (max-width: 1024px) {
  .featuredPost .innerWrapper{
    flex-direction: column;
  }

  .featuredPost .categoryBadge{
    display: none
  }

  .featuredPost .innerWrapper .caption a h3{
    margin-bottom: 0;
  }
}