.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0px 0px 6px 0px rgba(37,37,37,0.24);
  }
  
.nav {
    background-color: #ffffff;
    color: white;
   
}

.logo {
    font-size: 1.8rem;
    margin: 0.3rem 0;
    text-decoration: none;
    color: #000000;
    font-family: "Crimson Text", serif;
    display: flex;
    align-items: center;
}

.nav-links {
    width: 100%;
    border-top: solid 1px #e8e8e8;
}

.nav-links .container{
    padding: 0;
}



.nav-links a:hover {
}
