.newsletter{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center;
    border: 1px solid #edae77;
    height: auto;
}

.newsletter h2{
    font-size: 26px;
    font-family: "Roboto", sans-serif !important;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    color: #c65050;
}

.newsletter form{
    width: 100%;
}

.newsletter input[type=email]{
    height: 50px;
    background: #F9F7FC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    font: normal normal normal 16px / 19px Roboto, Arial, sans-serif;
    color: #000000;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    background: #F9F7FC 0% 0% no-repeat padding-box;
    border: 1px solid #edae77;
    box-shadow: none;
    padding: 6px 12px;
}

.newsletter button[type=submit]{
    height: 50px;
    background: #c65050 0% 0% no-repeat padding-box;
    border-radius: 5px;
    outline: 0;
    border-color: transparent;
    opacity: 1;
    text-align: center;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    text-transform: uppercase;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    
}

.newsletter button[type=submit]:hover{
    background: #edae77 0% 0% no-repeat padding-box;
    color: #c65050;
    cursor: pointer;
}

.newsletter button:active, .newsletter button.active {
    outline: 0;
    background-image: none;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.newsletter p{
    line-height: 26px;
}

.newsletter small{
    font-weight: 500;
    width: 100%;
    float: left;
    font-size: 12px;
    margin-top: 25px;
}