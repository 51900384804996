.mainMenu li{
    margin-right: 24px;
}

.mainMenu li a{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
    padding: 0.7rem 0;
    display: block;
    border-bottom: solid 3px transparent;
}

.mainMenu li a:hover{
    color:#c65050;
    text-decoration: none;
    cursor: pointer;
    border-bottom: solid 3px #c65050;
}

.mainMenu li a.active{
    color:#c65050;
    border-bottom: solid 3px #c65050;
}

.mainMenu{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0.5em;
}


@media only screen and (max-width: 1024px) {
    .mainMenu{
        flex-direction: row;
        width: 100%;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .mainMenu li a{
        font-size: 13px;
    }
}