@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  margin-top: 65px;
}

* {
  box-sizing: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.container-fluid {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}

.sidebar {
  background-color: #f4f4f4;
  padding: 1rem;
  flex: 1 1;
  border-radius: 4px;
}

.main {
  background-color: #ddd;
  padding: 1rem;
  flex: 3 1;
  margin: 0.5rem;
  border-radius: 4px;
}

.row {
  display: flex;
  /* flex-wrap: wrap;  */
  margin: 0;
  width: 100%;
}

/* Responsive styles for .row */
@media (max-width: 768px) {
  .row {
      flex-direction: column; /* Stack items vertically on small screens */
  }
}

@media (min-width: 769px) {
  .row {
      flex-direction: row; /* Ensure items are arranged horizontally on medium to large screens */
  }
}

.column.compact{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(20% - 1rem);
}

.column {
  border-radius: 4px;
  margin: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33.33% - 1rem);
}



.column-2 {
  border-radius: 4px;
  margin: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 1rem); 
}

.column-3 {
  border-radius: 4px;
  margin: 0.5rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 33.3% - 1rem);
}


@media (max-width: 768px) {
  .column {
    flex: 1 1 100%;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .column {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 1rem);
  }
}

@media (min-width: 768px) {
  .container, .container-fluid {
    flex-direction: column;
    align-items: stretch;
  }

  .nav {
    flex: 1 1;
    order: 1;
  }

  .main {
    flex: 3 1;
    order: 2;
  }

  .sidebar {
    flex: 1 1;
    order: 3;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

/* Media Queries for column-2 and column-3 */
@media (max-width: 768px) {
  .column-2, .column-3 {
    flex: 1 1 100%; /* Full width on small screens */
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .column-2 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.3% - 1rem); /* 2 columns layout for medium screens */
  }

  .column-3 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 33.3% - 1rem); /* Column 3 adjusts to fill the remaining space */
  }
}

@media (min-width: 1200px) {
  .column-2 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(33.3% - 1rem); /* Maintain 2 columns layout for large screens */
  }

  .column-3 {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 33.3% - 1rem); /* Maintain 3 columns layout for large screens */
  }
}

h1,h3,h4,h5,h6{
  font-family: 'Roboto Condensed', sans-serif;
}

p{
  font-family: Roboto, sans-serif;
}
h2{
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: italic;
}

.categoryBadge{
  background-color: #edae77;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}

.centered-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.centered-column {
  flex: 0 0 66.666%;
  max-width: 66.666%;
  margin: 0.5rem;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .centered-column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sidebar-column {
  flex: 0 0 10%;
  max-width: 10%;
  padding-left: 20px; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.featuredPost{

}

.featuredPost .innerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.featuredPost .innerWrapper .image{
    flex: 1.3 1;
  display: flex;
  justify-content: center;
}

.featuredPost .innerWrapper .image img {
    max-width: 100%;
    height: auto;
  }

.featuredPost .innerWrapper .caption{
    flex: 1 1;
  text-align: center;
}

.featuredPost .innerWrapper .caption a{
    text-decoration: none;
}

.featuredPost .innerWrapper .caption a:hover h3{
  color: #edae77;
}

.featuredPost .innerWrapper .caption a h3{
    color: #000000;
    font-size: 23px;
    font-family: "Crimson Text", serif;
    font-weight: 400;
}

.featuredPost .innerWrapper .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #898989;
}

@media only screen and (max-width: 1024px) {
  .featuredPost .innerWrapper{
    flex-direction: column;
  }

  .featuredPost .categoryBadge{
    display: none
  }

  .featuredPost .innerWrapper .caption a h3{
    margin-bottom: 0;
  }
}
.tPosts{

}

.tPosts h2{
    text-align: center;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 50px;
}

.tPosts h2::after{
    position: absolute;
    width: 50px;
    height: 1px;
    background-color: #edae77;
    content: '';
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.tPosts ul{
    list-style: none;
    padding: 0;
}

.tPosts ul li{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.tPosts ul li:last-child{
    margin-bottom: 0;
}

.tPosts ul li .image{
    width: 170px;
    height: 120px;
    position: relative;
}

.tPosts ul li .image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.tPosts ul li .caption{
    padding: 10px;
    flex: 2 1;
}

.tPosts ul li .caption a{
    text-decoration: none;
}

.tPosts ul li .caption a:hover h3{
    color: #edae77;
}

.tPosts ul li .caption a h3{
    font-family: 'Roboto Condensed', sans-serif;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.tPosts ul li .caption .postDetails .date{
    font-size: 12px;
    font-family: 'Roboto Condensed', sans-serif;
    color: #898989;
}




@media only screen and (max-width: 1024px) {
    .tPosts h2{
        margin-bottom: 25px;
        margin-top: 0;
    }

    .tPosts ul li .caption a h3{
        font-size: 16px;
    }

}
.uPosts{

}

.uPosts h2{
    text-align: center;
}

.uPosts.green h2{
    background-color: #c3d9c2;
  }
  
  .uPosts.lightgreen h2{
    background-color: #d3e1e1;
  }
  
  .uPosts.brown h2{
    background-color: #e8d3c4;
  }


.uPosts .lastRecentPost{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uPosts .lastRecentPost:last-child{
    margin-bottom: 0;
}

.uPosts .lastRecentPost .innerWrapper{
    width: 100%;
    height: 321px;

}

.uPosts .lastRecentPost .image{
    width: 100%;
    height: 225px;
    position: relative;
}

.uPosts .lastRecentPost .image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.uPosts .lastRecentPost .caption{
    padding: 10px;
    text-align: center;
}

.uPosts .lastRecentPost .caption{
    text-decoration: none;
}

.uPosts .lastRecentPost .caption a{
    text-decoration: none;
}

.uPosts .lastRecentPost .caption a:hover h3{
    color: #edae77;
}

.uPosts .lastRecentPost .caption h3{
    font-family: 'Roboto Condensed', sans-serif;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.uPosts .lastRecentPost .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    color: #898989;
}









.uPosts .lastRecentPosts{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.uPosts .lastRecentPosts .innerWrapper{
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    border-left: 0;
    border-right: 0;
}

.uPosts .lastRecentPosts .innerWrapper:nth-child(even){
    border-top: solid 1px #edae77;
    border-bottom: solid 1px #edae77;
}

.uPosts .lastRecentPosts .innerWrapper:last-child{
    margin-bottom: 0;
}

.uPosts .lastRecentPosts:last-child{
    margin-bottom: 0;
}

.uPosts .lastRecentPosts .image{
    width: 120px;
    height: 80px;
    position: relative;
}

.uPosts .lastRecentPosts .image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.uPosts .lastRecentPosts .caption{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2 1;
}

.uPosts .lastRecentPosts .caption a{
    text-decoration: none;
}

.uPosts .lastRecentPosts .caption a:hover h3{
    color: #edae77;
}

.uPosts .lastRecentPosts .caption h3{
    font-family: 'Roboto Condensed', sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.uPosts .lastRecentPosts .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: none;
    color: #898989;
}





@media only screen and (max-width: 1024px) {
    .uPosts .lastRecentPost .innerWrapper{
        height: auto;
    }

}
.newsletter{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    text-align: center;
    border: 1px solid #edae77;
    height: auto;
}

.newsletter h2{
    font-size: 26px;
    font-family: "Roboto", sans-serif !important;
    font-weight: 600;
    font-style: normal;
    margin: 0;
    color: #c65050;
}

.newsletter form{
    width: 100%;
}

.newsletter input[type=email]{
    height: 50px;
    background: #F9F7FC 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
    font: normal normal normal 16px / 19px Roboto, Arial, sans-serif;
    color: #000000;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    background: #F9F7FC 0% 0% no-repeat padding-box;
    border: 1px solid #edae77;
    box-shadow: none;
    padding: 6px 12px;
}

.newsletter button[type=submit]{
    height: 50px;
    background: #c65050 0% 0% no-repeat padding-box;
    border-radius: 5px;
    outline: 0;
    border-color: transparent;
    opacity: 1;
    text-align: center;
    letter-spacing: 0.6px;
    color: #FFFFFF;
    text-transform: uppercase;
    width: 100%;
    font-weight: 600;
    font-size: 24px;
    
}

.newsletter button[type=submit]:hover{
    background: #edae77 0% 0% no-repeat padding-box;
    color: #c65050;
    cursor: pointer;
}

.newsletter button:active, .newsletter button.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.newsletter p{
    line-height: 26px;
}

.newsletter small{
    font-weight: 500;
    width: 100%;
    float: left;
    font-size: 12px;
    margin-top: 25px;
}
.borderCol{
    /* border-left: solid 1px #e8e8e8;
    border-right: solid 1px #e8e8e8;
    padding: 0 15px; */
}
.header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0px 0px 6px 0px rgba(37,37,37,0.24);
  }
  
.nav {
    background-color: #ffffff;
    color: white;
   
}

.logo {
    font-size: 1.8rem;
    margin: 0.3rem 0;
    text-decoration: none;
    color: #000000;
    font-family: "Crimson Text", serif;
    display: flex;
    align-items: center;
}

.nav-links {
    width: 100%;
    border-top: solid 1px #e8e8e8;
}

.nav-links .container{
    padding: 0;
}



.nav-links a:hover {
}

.mainMenu li{
    margin-right: 24px;
}

.mainMenu li a{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #000000;
    text-decoration: none;
    padding: 0.7rem 0;
    display: block;
    border-bottom: solid 3px transparent;
}

.mainMenu li a:hover{
    color:#c65050;
    text-decoration: none;
    cursor: pointer;
    border-bottom: solid 3px #c65050;
}

.mainMenu li a.active{
    color:#c65050;
    border-bottom: solid 3px #c65050;
}

.mainMenu{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: 0 0.5em;
}


@media only screen and (max-width: 1024px) {
    .mainMenu{
        flex-direction: row;
        width: 100%;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .mainMenu li a{
        font-size: 13px;
    }
}
.cPost{
}

.cPost h2{
    text-align: center;
}



.cPost .innerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: whitesmoke;

}

.cPost .image{
    flex: 3 1;
    display: flex;
    /* justify-content: center; */
}

.cPost .image a{
    display: block;
    width: 100%;
}

.cPost .image img{
    max-width: 100%;
    height: auto;
    width: 100%;
    min-height: 272px;
    height: 520px;
    object-fit: cover;
}

.cPost .caption{
    flex: 1 1;
    text-align: center;
}

.cPost .caption{
    text-decoration: none;
    padding: 0 25px;
}

.cPost .caption a{
    text-decoration: none;
}

.cPost .caption a:hover h3{
    color: #edae77;
}

.cPost .caption h3{
    color: #000000;
    font-size: 23px;
    font-family: "Crimson Text", serif;
    font-weight: 400;
}

.cPost .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    color: #898989;
}




.cPosts{
}

.cPosts h2{
    text-align: center;
}



.cPosts .innerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

}

.cPosts .image{
    flex: 2.5 1;
    display: flex;
    /* justify-content: center; */
    overflow: hidden;
    width: 100%;
}

.cPosts .image a{
    display: block;
    height: 165px
}

.cPosts .image img{
    max-width: 100%;
    height: auto;

}

.cPosts .caption{
    flex: 1 1;
    text-align: center;
}

.cPosts .caption{
    text-decoration: none;
}

.cPosts .caption a{
    text-decoration: none;
}

.cPosts .caption a:hover h3{
    color: #edae77;
}

.cPosts .caption h3{
    color: #000000;
    font-size: 16px;
    font-family: "Crimson Text", serif;
    font-weight: 400;
    margin: 0;
}

.cPosts .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    color: #898989;
}


.cPosts .categGrid{
    flex-grow: unset;
    flex-basis: calc(100% - 50% - 1rem);
}

.cPosts .grid{
    display: grid;
grid-template-columns: repeat(5, 1fr);
grid-gap: 1rem;
gap: 1rem; 
width: 100%;
}

@media only screen and (max-width: 1024px) {
    .cPost .innerWrapper{
        flex-direction: column;
        background-color: #ffffff;
    }

    .cPosts .innerWrapper{
        margin-bottom: 15px;
    }

   
}
.breadcrumbs a{
    color: #000000;
    text-decoration: none;
    text-transform: capitalize;
}
.footer {
    background-color: #f6f3f3;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .footer-section {
    flex: 1 1 200px;
    padding: 20px;
    max-width: 500px;
    align-self: center;
  }
  
  .footer-section h3 {
    color: #000000;
    margin-bottom: 8px;
    text-align: left;
    font-weight: 400;
  }

  .footer-section small{
    text-align: left;
    font-size: 10px;
    float: left;
    margin-top: 20px;
    color: #000000;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 18px;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    /* padding-top: 10px; */
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  }
  



.newsletter-form {
    display: flex; 
    align-items: center; 
}

.newsletter-form input {
    flex: 1 1; 
    margin-right: 10px; 
    padding: 12px; 
    border: 1px solid #ced4da; 
    border-radius: 5px; 
    font-size: 13px; 
}

.newsletter-form input:focus {
    border-color: #007bff; 
    outline: none; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}

.newsletter-form button {
    padding: 10px 20px; 
    font-size: 16px; 
    border: none; 
    border-radius: 5px; 
    background-color: #c65050; 
    color: #ffffff; 
    cursor: pointer; 
    font-weight: 600;
    transition: background-color 0.3s ease; 
}

.newsletter-form button:hover {
    background-color: #edae77; 
    color: #ffffff
}


.footer-section.social{
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section.social p{
  color: #454545;
  font-size: 12px;
}
.pDetail{

}

.pDetail h1{
    font-size: 4em;
    margin-top: 0;
}

.pDetail .date{
    font-size: 12px;
    color: #505d68;
    text-transform: uppercase;
    text-align: right;
    width: 100%;
    display: block;
}

.pDetail .detail{
    /* padding: 0 55px; */
}

.pDetail .detail p{
    line-height: 26px;
}

.pDetail .detail .has-small-font-size {
    font-size: 0.875rem;
}

.pDetail .detail .has-medium-font-size {
    font-size: 1rem; /* 16px, same as p */
}

.pDetail .detail .has-large-font-size {
    font-size: 1.125rem; /* 18px */
}

.pDetail .detail .has-x-large-font-size {
    font-size: 1.25rem; /* 20px */
}

.pDetail .detail .has-xx-large-font-size {
    font-size: 1.5rem; /* 24px */
}


.pDetail .detail figure{
    padding: 0;
    margin: 0;
}

.pDetail .detail figure img{
    object-fit: cover !important;
    width: 100% !important;
}


.sticky-sidebar {
    position: sticky;
    top: 139.91px; /* Distance from the top of the viewport */
    padding: 15px; /* Padding */
    border-radius: 4px; /* Rounded corners */
    max-width: 250px; /* Set a max width for the sidebar */
  }

  .sticky-sidebar.autowidth{
    max-width: 100%;
  }
  
  .sticky-sidebar h2 {
    font-size: 1.5em; /* Adjust font size */
  }
  
  .sticky-sidebar ul {
    list-style-type: none; /* Remove list style */
    padding: 0; /* Remove padding */
  }
  
  .sticky-sidebar ul li {
    margin: 10px 0; /* Space between items */
  }
  
  .sticky-sidebar ul li a {
    text-decoration: none; /* Remove underline */
    color: #333; /* Text color */
  }
  
