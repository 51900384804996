.footer {
    background-color: #f6f3f3;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .footer-section {
    flex: 1 1 200px;
    padding: 20px;
    max-width: 500px;
    align-self: center;
  }
  
  .footer-section h3 {
    color: #000000;
    margin-bottom: 8px;
    text-align: left;
    font-weight: 400;
  }

  .footer-section small{
    text-align: left;
    font-size: 10px;
    float: left;
    margin-top: 20px;
    color: #000000;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer-section ul li a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .social-icons a {
    color: #fff;
    margin: 0 10px;
    text-decoration: none;
    font-size: 18px;
  }
  
  .footer-bottom {
    border-top: 1px solid #444;
    /* padding-top: 10px; */
  }
  
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
  }
  



.newsletter-form {
    display: flex; 
    align-items: center; 
}

.newsletter-form input {
    flex: 1; 
    margin-right: 10px; 
    padding: 12px; 
    border: 1px solid #ced4da; 
    border-radius: 5px; 
    font-size: 13px; 
}

.newsletter-form input:focus {
    border-color: #007bff; 
    outline: none; 
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); 
}

.newsletter-form button {
    padding: 10px 20px; 
    font-size: 16px; 
    border: none; 
    border-radius: 5px; 
    background-color: #c65050; 
    color: #ffffff; 
    cursor: pointer; 
    font-weight: 600;
    transition: background-color 0.3s ease; 
}

.newsletter-form button:hover {
    background-color: #edae77; 
    color: #ffffff
}


.footer-section.social{
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-section.social p{
  color: #454545;
  font-size: 12px;
}