.pDetail{

}

.pDetail h1{
    font-size: 4em;
    margin-top: 0;
}

.pDetail .date{
    font-size: 12px;
    color: #505d68;
    text-transform: uppercase;
    text-align: right;
    width: 100%;
    display: block;
}

.pDetail .detail{
    /* padding: 0 55px; */
}

.pDetail .detail p{
    line-height: 26px;
}

.pDetail .detail .has-small-font-size {
    font-size: 0.875rem;
}

.pDetail .detail .has-medium-font-size {
    font-size: 1rem; /* 16px, same as p */
}

.pDetail .detail .has-large-font-size {
    font-size: 1.125rem; /* 18px */
}

.pDetail .detail .has-x-large-font-size {
    font-size: 1.25rem; /* 20px */
}

.pDetail .detail .has-xx-large-font-size {
    font-size: 1.5rem; /* 24px */
}


.pDetail .detail figure{
    padding: 0;
    margin: 0;
}

.pDetail .detail figure img{
    object-fit: cover !important;
    width: 100% !important;
}
