.cPosts{
}

.cPosts h2{
    text-align: center;
}



.cPosts .innerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

}

.cPosts .image{
    flex: 2.5 1;
    display: flex;
    /* justify-content: center; */
    overflow: hidden;
    width: 100%;
}

.cPosts .image a{
    display: block;
    height: 165px
}

.cPosts .image img{
    max-width: 100%;
    height: auto;

}

.cPosts .caption{
    flex: 1 1;
    text-align: center;
}

.cPosts .caption{
    text-decoration: none;
}

.cPosts .caption a{
    text-decoration: none;
}

.cPosts .caption a:hover h3{
    color: #edae77;
}

.cPosts .caption h3{
    color: #000000;
    font-size: 16px;
    font-family: "Crimson Text", serif;
    font-weight: 400;
    margin: 0;
}

.cPosts .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    color: #898989;
}


.cPosts .categGrid{
    flex-grow: unset;
    flex-basis: calc(100% - 50% - 1rem);
}

.cPosts .grid{
    display: grid;
grid-template-columns: repeat(5, 1fr);
gap: 1rem; 
width: 100%;
}

@media only screen and (max-width: 1024px) {
    .cPost .innerWrapper{
        flex-direction: column;
        background-color: #ffffff;
    }

    .cPosts .innerWrapper{
        margin-bottom: 15px;
    }

   
}