.uPosts{

}

.uPosts h2{
    text-align: center;
}

.uPosts.green h2{
    background-color: #c3d9c2;
  }
  
  .uPosts.lightgreen h2{
    background-color: #d3e1e1;
  }
  
  .uPosts.brown h2{
    background-color: #e8d3c4;
  }


.uPosts .lastRecentPost{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uPosts .lastRecentPost:last-child{
    margin-bottom: 0;
}

.uPosts .lastRecentPost .innerWrapper{
    width: 100%;
    height: 321px;

}

.uPosts .lastRecentPost .image{
    width: 100%;
    height: 225px;
    position: relative;
}

.uPosts .lastRecentPost .image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.uPosts .lastRecentPost .caption{
    padding: 10px;
    text-align: center;
}

.uPosts .lastRecentPost .caption{
    text-decoration: none;
}

.uPosts .lastRecentPost .caption a{
    text-decoration: none;
}

.uPosts .lastRecentPost .caption a:hover h3{
    color: #edae77;
}

.uPosts .lastRecentPost .caption h3{
    font-family: 'Roboto Condensed', sans-serif;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
    margin: 0;
}

.uPosts .lastRecentPost .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: block;
    color: #898989;
}









.uPosts .lastRecentPosts{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.uPosts .lastRecentPosts .innerWrapper{
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    border-left: 0;
    border-right: 0;
}

.uPosts .lastRecentPosts .innerWrapper:nth-child(even){
    border-top: solid 1px #edae77;
    border-bottom: solid 1px #edae77;
}

.uPosts .lastRecentPosts .innerWrapper:last-child{
    margin-bottom: 0;
}

.uPosts .lastRecentPosts:last-child{
    margin-bottom: 0;
}

.uPosts .lastRecentPosts .image{
    width: 120px;
    height: 80px;
    position: relative;
}

.uPosts .lastRecentPosts .image img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.uPosts .lastRecentPosts .caption{
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
}

.uPosts .lastRecentPosts .caption a{
    text-decoration: none;
}

.uPosts .lastRecentPosts .caption a:hover h3{
    color: #edae77;
}

.uPosts .lastRecentPosts .caption h3{
    font-family: 'Roboto Condensed', sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.uPosts .lastRecentPosts .caption .postDetails .date{
    font-size: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    display: none;
    color: #898989;
}





@media only screen and (max-width: 1024px) {
    .uPosts .lastRecentPost .innerWrapper{
        height: auto;
    }

}