
.sticky-sidebar {
    position: sticky;
    top: 139.91px; /* Distance from the top of the viewport */
    padding: 15px; /* Padding */
    border-radius: 4px; /* Rounded corners */
    max-width: 250px; /* Set a max width for the sidebar */
  }

  .sticky-sidebar.autowidth{
    max-width: 100%;
  }
  
  .sticky-sidebar h2 {
    font-size: 1.5em; /* Adjust font size */
  }
  
  .sticky-sidebar ul {
    list-style-type: none; /* Remove list style */
    padding: 0; /* Remove padding */
  }
  
  .sticky-sidebar ul li {
    margin: 10px 0; /* Space between items */
  }
  
  .sticky-sidebar ul li a {
    text-decoration: none; /* Remove underline */
    color: #333; /* Text color */
  }
  