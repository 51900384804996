@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');





body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  margin-top: 65px;
}

* {
  box-sizing: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.container-fluid {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  margin-top: auto;
}

.sidebar {
  background-color: #f4f4f4;
  padding: 1rem;
  flex: 1;
  border-radius: 4px;
}

.main {
  background-color: #ddd;
  padding: 1rem;
  flex: 3;
  margin: 0.5rem;
  border-radius: 4px;
}

.row {
  display: flex;
  /* flex-wrap: wrap;  */
  margin: 0;
  width: 100%;
}

/* Responsive styles for .row */
@media (max-width: 768px) {
  .row {
      flex-direction: column; /* Stack items vertically on small screens */
  }
}

@media (min-width: 769px) {
  .row {
      flex-direction: row; /* Ensure items are arranged horizontally on medium to large screens */
  }
}

.column.compact{
  flex: 1 1 calc(20% - 1rem);
}

.column {
  border-radius: 4px;
  margin: 0.5rem;
  flex: 1 1 calc(33.33% - 1rem);
}



.column-2 {
  border-radius: 4px;
  margin: 0.5rem;
  flex: 1 1 calc(50% - 1rem); 
}

.column-3 {
  border-radius: 4px;
  margin: 0.5rem;
  flex: 1 1 calc(100% - 33.3% - 1rem);
}


@media (max-width: 768px) {
  .column {
    flex: 1 1 100%;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .column {
    flex: 1 1 calc(50% - 1rem);
  }
}

@media (min-width: 768px) {
  .container, .container-fluid {
    flex-direction: column;
    align-items: stretch;
  }

  .nav {
    flex: 1;
    order: 1;
  }

  .main {
    flex: 3;
    order: 2;
  }

  .sidebar {
    flex: 1;
    order: 3;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

/* Media Queries for column-2 and column-3 */
@media (max-width: 768px) {
  .column-2, .column-3 {
    flex: 1 1 100%; /* Full width on small screens */
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .column-2 {
    flex: 1 1 calc(33.3% - 1rem); /* 2 columns layout for medium screens */
  }

  .column-3 {
    flex: 1 1 calc(100% - 33.3% - 1rem); /* Column 3 adjusts to fill the remaining space */
  }
}

@media (min-width: 1200px) {
  .column-2 {
    flex: 1 1 calc(33.3% - 1rem); /* Maintain 2 columns layout for large screens */
  }

  .column-3 {
    flex: 1 1 calc(100% - 33.3% - 1rem); /* Maintain 3 columns layout for large screens */
  }
}

h1,h3,h4,h5,h6{
  font-family: 'Roboto Condensed', sans-serif;
}

p{
  font-family: Roboto, sans-serif;
}
h2{
  font-family: "Crimson Text", serif;
  font-weight: 400;
  font-style: italic;
}

.categoryBadge{
  background-color: #edae77;
  color: #ffffff;
  padding: 5px;
  font-size: 12px;
  font-family: 'Roboto Condensed', sans-serif;
}

.centered-columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
}

.centered-column {
  flex: 0 0 66.666%;
  max-width: 66.666%;
  margin: 0.5rem;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .centered-column {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sidebar-column {
  flex: 0 0 10%;
  max-width: 10%;
  padding-left: 20px; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}